import React from "react";
import {FormGroup} from "reactstrap";
import {observer} from "mobx-react";
import RadioOption from "./RadioOption";
import {observable} from "mobx";
import {AnswerOption} from "../../models/answer-option";

interface IAnswerAreaProps {
    options: AnswerOption[];
    handleSelectionChanged: (option: AnswerOption) => void;
}

@observer
class SingleSelectionAnswerArea extends React.Component<IAnswerAreaProps, any>{
    @observable
    private selectedOption?: AnswerOption;

    render() {
        return (
            this.props.options.map(option => (
                <FormGroup key={`option-${option.id}`} check>
                    <RadioOption option={option} handleSelectionChanged={this.onSelect} checked={!this.selectedOption ? false : this.selectedOption.id === option.id} />
                </FormGroup>
            ))
        );
    }

    private onSelect = (option: AnswerOption) => {
        this.selectedOption = option;
        this.props.handleSelectionChanged(option);
    }
}

export default SingleSelectionAnswerArea;