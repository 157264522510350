import React from 'react';
import './App.css';
import { BrowserRouter, Switch } from 'react-router-dom';
import {Route} from "react-router";
import EvaluationPage from "./components/exam/EvaluationPage";
import HomePage from "./components/exam/HomePage";

const App = () => {
  return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path={"/"} component={HomePage} />
            <Route path={"/evaluation"} component={EvaluationPage} />
          </Switch>
        </div>
      </BrowserRouter>
  );
}

export default App;
