import React from "react";
import {Label, Input} from "reactstrap";
import {observer} from "mobx-react";
import {AnswerOption} from "../../models/answer-option";

interface IAnswerAreaProps {
    option: AnswerOption;
    handleSelectionChanged: (option: AnswerOption) => void;
}

@observer
class CheckboxOption extends React.Component<IAnswerAreaProps, any>{
    render() {
        return (
            <Label check onChange={this.onSelect} style={{display: "flex"}}>
                <Input type="checkbox" />
                {this.props.option.text}
            </Label>
        );
    }

    private onSelect = (e: any) => {
        this.props.option.checked = e.target.checked;
        this.props.handleSelectionChanged(this.props.option);
    }
}

export default CheckboxOption;