import React from "react";
import {FormGroup, Label, Input} from "reactstrap";
import {observer} from "mobx-react";
import SingleSelectionAnswerArea from "./SingleSelectionAnswerArea";
import MultipleSelectionAnswerArea from "./MultipleSelectionAnswerArea";
import { IQuestion } from "../../models/question.js";
import {observable} from "mobx";
import {UserService} from "../../services/user.service";
import {Answer} from "../../models/answer";
import {AnswerOption} from "../../models/answer-option";
import { debounce } from "throttle-debounce";

interface IAnswerAreaProps {
    item: IQuestion;
}

@observer
class AnswerArea extends React.Component<IAnswerAreaProps, any>{
    private readonly userService: UserService;
    @observable
    private selectedOptionsIds: number[] = [];
    @observable
    private argumentation: string = "";

    private commitDebounced = debounce(500, async () => {
        const answer: Answer = {
            questionId: this.props.item.id!,
            optionsIds: this.selectedOptionsIds,
            argumentation: this.argumentation
        };
        await this.userService.commitAnswer(answer);
    });

    constructor(props: IAnswerAreaProps) {
        super(props);
        this.userService = UserService.getInstance();
    }

    render() {
        return (
            <React.Fragment>
                <FormGroup tag="fieldset">
                    {
                        this.props.item.singleChoice ?
                        <SingleSelectionAnswerArea options={this.props.item.options || []} handleSelectionChanged={this.handleSingleSelection}/> :
                        <MultipleSelectionAnswerArea options={this.props.item.options || []} handleSelectionChanged={this.handleMultipleSelection}/>
                    }
                </FormGroup>
                {
                    this.props.item.needArgumentation &&
                    <FormGroup>
                        <Label className="float-left">Va rog sa argumentati raspunsul !</Label>
                        <Input type="textarea" name="text" value={this.argumentation} onChange={this.onArgumentationChange}/>
                    </FormGroup>
                }
            </React.Fragment>
        );
    }

    private onArgumentationChange = async (e: any) => {
        this.argumentation = e.target.value;
        await this.commitDebounced();
    }

    private handleSingleSelection = async (option: AnswerOption) => {
        this.selectedOptionsIds = option.checked ? [option.id!] : [];
        await this.commitDebounced();
    }

    private handleMultipleSelection = async (options: AnswerOption[]) => {
        this.selectedOptionsIds = options.reduce((acc, option) => acc.concat(option.checked ? [option.id!] : []), [] as number[]);
        await this.commitDebounced();
    }
}

export default AnswerArea;