import React, {useEffect, useState} from "react";
import {UserService} from "../../services/user.service";
import ExamPage from "./ExamPage";
import LoginPage from "../login/LoginPage";

const HomePage: React.FC = () => {
    const userService: UserService = UserService.getInstance();
    const [loggedIn, setLoggedIn] = useState(userService.loggedIn.getValue());

    useEffect(() => {
        userService.loggedIn.subscribe(x => setLoggedIn(x));
    }, []);

    return (
        <div className="App">
            {
                loggedIn &&
                <ExamPage/>
            }
            {
                !loggedIn &&
                <LoginPage/>
            }
        </div>
    );
}

export default HomePage;