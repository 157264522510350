import React from "react";
import {Form, FormGroup, Label, Input, Button, Container, Row, Col, Jumbotron, Card, CardBody, Alert} from "reactstrap";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {HttpService} from "../../services/http.service";
import {UserService} from "../../services/user.service";

@observer
class LoginPage extends React.Component<any, any>{
    @observable
    private username: string = "";
    @observable
    private password: string = "";
    @observable
    private loggedInFailed: boolean = false;

    render() {
        return (
            <Container>
                <Row>
                    <Col />
                    <Col lg="10">
                        <Jumbotron>
                            <h3>
                                <b style={{color: "red"}}>Va rog sa introduceti adresa de email de ULBS si parola primita prin email la aceasta adresa</b>
                            </h3>
                            <hr />
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col />
                                        <Col lg="8">
                                            <Form>
                                                <FormGroup>
                                                    <Label className="float-left">Email</Label>
                                                    <Input type="email" name="email" id="exampleEmail" placeholder="email ULBS" value={this.username} onChange={this.onUserChange} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="float-left">Parola</Label>
                                                    <Input type="password" name="password" id="examplePassword" placeholder="parola email ULBS" value={this.password} onChange={this.onUserPassword} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Button onClick={this.submit}>Logare</Button>
                                                </FormGroup>
                                                {
                                                    this.loggedInFailed &&
                                                    <Form>
                                                        <Alert color="danger">
                                                            Adresa utilizata sau parola sunt gresite. Va rog sa incercati din nou.
                                                        </Alert>
                                                    </Form>
                                                }
                                            </Form>
                                        </Col>
                                        <Col />
                                    </Row>
                                </CardBody>
                            </Card>
                        </Jumbotron>
                    </Col>
                    <Col />
                </Row>
            </Container>
        );
    }

    private onUserChange = (e: any) => {
        this.username = e.target.value || "";
    }

    private onUserPassword = (e: any) => {
        this.password = e.target.value || "";
    }

    private submit = async () => {
        const userService: UserService = UserService.getInstance();

        const response = await fetch(`${HttpService.baseUrl}/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: this.username,
                password: this.password
            })
        });
        const obj = await response.json();
        if(obj.err) {
            this.loggedInFailed = true;
            console.log(obj.err);
        } else {
            this.loggedInFailed = false;
            const loggedInUser = obj.user;
            userService.user = loggedInUser
            userService.loggedIn.next(true);
        }
    }
}

export default LoginPage;