import React from "react";
import {FormGroup} from "reactstrap";
import {observer} from "mobx-react";
import CheckboxOption from "./CheckboxOption";
import {AnswerOption} from "../../models/answer-option";

interface IAnswerAreaProps {
    options: AnswerOption[];
    handleSelectionChanged: (options: AnswerOption[]) => void;
}

@observer
class MultipleSelectionAnswerArea extends React.Component<IAnswerAreaProps, any>{
    render() {
        return (
            this.props.options.map(option => (
                <FormGroup key={`option-${option.id}`} check>
                    <CheckboxOption option={option} handleSelectionChanged={this.onSelectionChanged} />
                </FormGroup>
            ))
        );
    }

    private onSelectionChanged = (option: AnswerOption) => {
        this.props.options.forEach(x => x.id === option.id ? x.checked = option.checked : x.checked);
        this.props.handleSelectionChanged(this.props.options);
    }
}

export default MultipleSelectionAnswerArea;