import { Dictionary } from "lodash"

/**
 * @questionId Store the question id from config file
 * @optionsIds Store at least 1 numeric id of the selected option(s)
 */
export interface IStudentAnswer {
    questionId: number;
    optionsIds: number[];
    argumentation?: string;
}

export class StudentAnswer {
    public static StudentAnswers: Dictionary<IStudentAnswer> = {};
}