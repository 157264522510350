import React from "react";
import {Alert, Badge, Form, FormGroup, Label} from "reactstrap";
import Timer from "../Timer";
import {observer} from "mobx-react";
import AnswerArea from "./AnswerArea";
import {StudentAnswer} from "../../services/student-answer";
import {observable} from "mobx";
import {IQuestion} from "../../models/question";
import {IRemoteQuestion} from "../../models/remote-question";
import {ExamStatus} from "../../models/exam-status.enum";
import {HttpService} from "../../services/http.service";
import * as _ from "lodash";
import {SessionService} from "../../services/session.service";
import {UserService} from "../../services/user.service";

@observer
class ExamPage extends React.Component<any, any>{
    private readonly userService: UserService = UserService.getInstance();

    @observable
    private currentQuestion?: IQuestion;
    @observable
    private examFinished: boolean = false;

    private eventSource?: EventSource;

    async componentDidMount() {
        if(!SessionService.ExamStarted) {
            const user = this.userService.user;
            if(!user) { return; }
            this.eventSource = new EventSource(`${HttpService.baseUrl}/sse/${user.id}`)
            this.eventSource.onmessage = (e) => this.handleMessageReceive(JSON.parse(e.data));
            this.eventSource.addEventListener("message", (e) => this.handleMessageReceive(JSON.parse(e.data)));
        } else {
            await this.displayCurrentQuestion();
        }
    }

    componentWillUnmount() {
        if(this.eventSource) {
            this.eventSource.close();
        }
    }

    render() {
        return (
            <Form className="d-inline-block" style={{marginTop: "10%"}}>
                {
                    !this.examFinished && this.currentQuestion &&
                    <React.Fragment>
                        <FormGroup>
                            <Label>
                                <div className="alert alert-primary fade show">
                                    <legend> {this.currentQuestion.text} ({this.currentQuestion.weight}pct.)</legend>
                                </div>
                            </Label>
                        </FormGroup>
                        <AnswerArea  key={_.uniqueId(this.currentQuestion.id.toString())} item={this.currentQuestion}/>
                        <FormGroup>
                            <Timer key={_.uniqueId(this.currentQuestion.id.toString())} duration={this.currentQuestion.duration} onComplete={this.onTimerComplete}/>
                        </FormGroup>
                    </React.Fragment>
                }
                {
                    !this.examFinished && !this.currentQuestion &&
                    <div>Examenul incepe in curand...</div>
                }
                {
                    this.examFinished &&
                    <h1><Badge color="success">Examenul s-a incheiat</Badge></h1>
                }
            </Form>
        );
    }

    private displayCurrentQuestion = async() => {
        const remoteQuestion = await this.getCurrentQuestion();

        if(!remoteQuestion) { return; }

        this.setCurrentQuestion(remoteQuestion);
    }

    private handleMessageReceive = async (data: {status: ExamStatus}) => {
        if(!data) { return; }
        if(data.status === ExamStatus.Starting) {
            await this.displayCurrentQuestion();
            // if(this.eventSource) {
            //     this.eventSource.close();
            // }
        } else if(data.status === ExamStatus.Finished) {
            this.examFinished = true;
        }
    }

    private setCurrentQuestion = (remoteQuestion: IRemoteQuestion) => {
        this.currentQuestion = remoteQuestion.question;

        if(!this.currentQuestion) { return; }

        this.currentQuestion.duration = remoteQuestion.remainingTime;
    }

    private getCurrentQuestion = async () => {
        const user = this.userService.user;
        if(!user) { return; }

        const response = await fetch(`${HttpService.baseUrl}/getCurrentQuestion/${user.id}`);
        const remoteQuestion = (await response.json()) as IRemoteQuestion;
        return remoteQuestion;
    }

    private onTimerComplete = async () => {
        const remoteQuestion = await this.getCurrentQuestion();

        if(!remoteQuestion || remoteQuestion.status === ExamStatus.Finished) {
            this.examFinished = true;
            console.log("Exam finished", StudentAnswer.StudentAnswers);
            return;
        }

        this.setCurrentQuestion(remoteQuestion);
    }
}

export default ExamPage;