export class SessionService {
    public static ExamStarted: boolean = false;

    private static _instance: SessionService;

    public static getInstance(): SessionService {
        if (!SessionService._instance) {
            SessionService._instance = new SessionService();
        }

        return SessionService._instance;
    }
}