import {HttpService} from "./http.service";
import {SessionService} from "./session.service";

export class InitializerService {
    private static _instance: InitializerService;

    public static getInstance(): InitializerService {
        if (!InitializerService._instance) {
            InitializerService._instance = new InitializerService();
        }

        return InitializerService._instance;
    }

    public async init() {
        const response = await fetch(`${HttpService.baseUrl}/examStarted`);
        const started = (await response.json()).started;
        SessionService.ExamStarted = started;
    }
}