import React from "react";
import {observer} from "mobx-react";
import {Form, FormGroup, Input, Label, Button, Table, Badge} from "reactstrap";
import {ExpectedAnswer} from "../../models/expected-answer";
import {EvaluationService} from "../../services/evaluation.service";
import {Answer} from "../../models/answer";
import { Dictionary } from "lodash";
import {Client} from "../../models/client";
import {observable} from "mobx";

@observer
class EvaluationPage extends React.Component<any, any>{
    private studentsAnswers: Dictionary<Dictionary<Answer>> =
            JSON.parse(`
            {
               "0":{
                  "0":{
                     "questionId":0,
                     "optionsIds":[
                        1
                     ],
                     "argumentation":""
                  },
                  "1":{
                     "questionId":1,
                     "optionsIds":[
                        2
                     ],
                     "argumentation":""
                  },
                  "2":{
                     "questionId":2,
                     "optionsIds":[
                        3
                     ],
                     "argumentation":""
                  },
                  "3":{
                     "questionId":3,
                     "optionsIds":[
                        1,
                        2
                     ],
                     "argumentation":""
                  },
                  "4":{
                     "questionId":4,
                     "optionsIds":[
                        3
                     ],
                     "argumentation":""
                  },
                  "5":{
                     "questionId":5,
                     "optionsIds":[
                        1
                     ],
                     "argumentation":""
                  },
                  "6":{
                     "questionId":6,
                     "optionsIds":[
                        2,
                        1
                     ],
                     "argumentation":""
                  }
               }
            }
        `);
    private expectedAnswers: ExpectedAnswer[] = [];
    @observable
    private evaluatedResponses: Client[] = [];

    async componentDidMount() {
        const evaluationService: EvaluationService = EvaluationService.getInstance();
        const expectedAnswers = await evaluationService.getExpectedAnswers();
        this.expectedAnswers = expectedAnswers || [];
        console.log(this.expectedAnswers);
    }

    render() {
        return (
            <Form>
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <Label for="exampleText">Students answers</Label>
                            <Input type="textarea" name="text" id="studentsAnswers" style={{height: "80vh"}} defaultValue={`
                            {
   "0":{
      "0":{
         "questionId":0,
         "optionsIds":[
            1
         ],
         "argumentation":""
      },
      "1":{
         "questionId":1,
         "optionsIds":[
            2
         ],
         "argumentation":""
      },
      "2":{
         "questionId":2,
         "optionsIds":[
            3
         ],
         "argumentation":""
      },
      "3":{
         "questionId":3,
         "optionsIds":[
            1,
            2
         ],
         "argumentation":""
      },
      "4":{
         "questionId":4,
         "optionsIds":[
            3
         ],
         "argumentation":""
      },
      "5":{
         "questionId":5,
         "optionsIds":[
            1
         ],
         "argumentation":""
      },
      "6":{
         "questionId":6,
         "optionsIds":[
            2,
            1
         ],
         "argumentation":""
      }
   }
}
                            `} onChange={this.answerAreaChanged}/>
                        </FormGroup>
                        <Button color="primary" size="lg" block onClick={this.startEvaluation}>Evaluate</Button>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <Label for="exampleText">Students evaluation</Label>
                            <Table responsive={true} size="sm" >
                                <thead>
                                    <tr>
                                        <th>Nume</th>
                                        <th>Prenume</th>
                                        {
                                            this.evaluatedResponses.length > 0 &&
                                            Object.keys(this.evaluatedResponses[0].evaluatedResponses || []).map((x, index) => (
                                                <th key={x}>{index} ({this.evaluatedResponses[0].evaluatedResponses[x].question.weight}pct.)</th>
                                            ))
                                        }
                                        <th>Nota</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.evaluatedResponses.map((x, index) => (
                                        <tr key={x.id}>
                                            <td>{x.lastName}</td>
                                            <td>{x.firstName}</td>
                                            {
                                                Object.keys(x.evaluatedResponses).map((response, index) => (
                                                    <td key={`${response}-${index}`}>
                                                        <Badge color={`${x.evaluatedResponses[response].evaluation ? "success" : "danger"}`}>{ x.evaluatedResponses[response].evaluation ? "Corect" : "Incorect" }</Badge>
                                                    </td>
                                                ))
                                            }
                                            <td>
                                                {
                                                    Object.keys(x.evaluatedResponses).reduce((acc, current) => {
                                                        if(x.evaluatedResponses[current].evaluation) {
                                                            acc += Number(x.evaluatedResponses[current].question.weight || 0);
                                                        }
                                                        return acc;
                                                    }, 0)
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </FormGroup>
                    </div>
                </div>
            </Form>
        );
    }

    private answerAreaChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        try{
            this.studentsAnswers = JSON.parse(value);
        } catch (e) {
            console.error("Can not parse value from answer area.")
        }
    }

    private startEvaluation = async () => {
        const evaluationService: EvaluationService = EvaluationService.getInstance();
        const response = await evaluationService.evaluateExam(this.studentsAnswers);
        console.log(response);
        this.evaluatedResponses = response;
    }
}

export default EvaluationPage;